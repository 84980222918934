
import { Breadcrumb, dashboardPath } from "@/components/top-bar";
import { LearningTopic } from "@/services/v1/member/learning/types";
import { setBreadcrumb } from "@/utils/breadcrumb";
import {
  computed,
  defineAsyncComponent,
  defineComponent,
  onMounted,
  reactive,
} from "vue";
import { useRoute } from "vue-router";
import { helper as $h } from "@/utils/helper";
import { DataTableHeader } from "@/global-components/data-table/types";

const DataTable = defineAsyncComponent(
  () => import(`@/global-components/data-table/Main.vue`)
);

const servicesV1MemberLearning = async () =>
  import(`@/services/v1/member/learning/index`);

export default defineComponent({
  components: {
    DataTable,
  },
  setup() {
    // START === setup breadcrumb =====================================================
    const route = useRoute();
    const categoryCode = route.params?.categoryCode || "";
    const { learningTitle } = route.query;

    const pathMemberMateri = `/member/materi`;
    const breadcrumb: Breadcrumb[] = [
      {
        title: "Dashboard",
        to: dashboardPath,
      },
      {
        title: "Materi Pembelajaran",
        to: pathMemberMateri,
      },
      {
        title: `Materi "${learningTitle}"`,
        to: `${pathMemberMateri}/${categoryCode}`,
        disabled: true,
      },
    ];

    onMounted(() => {
      setBreadcrumb(breadcrumb);
    });
    // END === setup breadcrumb =======================================================

    // START === SETUP TABLE ==========================================================
    const tabelHeaders = [
      {
        text: "Bidang",
        value: "title",
      },
      {
        text: "Jumlah Materi",
        value: "jumlahMateri",
        headerClass: "w-44",
      },
      // {
      //   text: "Last Update",
      //   value: "updatedAt",
      //   headerClass: "w-48",
      // },
      {
        text: "Akses Materi",
        value: "action",
        headerClass: "w-24",
        align: "center",
      },
    ] as DataTableHeader[];
    // END === SETUP TABLE ============================================================

    // START === Function for get list topics ============================================
    const dataTopic = reactive({
      list: [] as LearningTopic[],
      loading: false,

      // for pagination
      page: 1,
      pageSize: 10,
      totalData: 1,
    });

    const getTopics = async () => {
      const { getMemberLearningTopics } = await servicesV1MemberLearning();

      dataTopic.loading = true;

      try {
        const hit = await getMemberLearningTopics(categoryCode as string);

        const resData = hit?.response;

        console.log("resData ", resData);

        dataTopic.list = resData;
      } catch (err: any) {
        console.error("err getTopics ", err, err?.response);
      } finally {
        dataTopic.loading = false;
      }
    };

    const detailListDocs = (
      topicId: string,
      topicTitle: string,
      type: "ebook" | "video"
    ) => {
      return {
        path: `${pathMemberMateri}/${categoryCode}/${type}`,
        query: {
          ...route.query,
          topicId,
          topicTitle,
        },
      };
    };

    const dataTopicItems = computed(() =>
      dataTopic.list.map((topic: any) => ({
        ...topic,
      }))
    );

    onMounted(() => {
      getTopics();
    });
    // END === Function for get list topics ==============================================

    return {
      learningTitle,
      dataTopicItems,
      tabelHeaders,
      dataTopic,
      $h,
      detailListDocs,
    };
  },
});
